import React from "react"
import "./App.css"
import { checkJob, sendRequest } from "./api/generate"

// frequency we check for updates in progress (ms)
const CHECK_INTERVAL = 1000

function App() {
	const [prompt, setPrompt] = React.useState<string | null>(null)
  const [jobId, setJobId] = React.useState<string>("")
  const [progress, setProgress] = React.useState<number>(0)

	const [img, setImg] = React.useState<string>("https://cdn.discordapp.com/attachments/629435177269067796/1031833616159883264/unknown.png")

	const onSubmit = () => {
    if (!prompt) {
      return
    }
    sendRequest(prompt).then(res => {
      setJobId(res.job_id)

      const interval = setInterval(() => {
        // setProgress(progress => progress+10);
        checkJob(jobId).then(res => {
          if (res.status === "complete") {
            if (res.file_url)
              setImg(res.file_url)
            setProgress(100);
            clearInterval(interval)
          }
          else if (res.status === "running") {
            setProgress(res.progress || 0);
          }
        }).catch(_err => {/* TODO */})
      }, CHECK_INTERVAL)

    }).catch(_err => {/* TODO */})
  }

	return (
		<div className="App">
			<header className="App-header">
				<div className="container">
					<div className="sm:col-span-2">
						<label className="prompt block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
							Prompt
						</label>
						<textarea
							id="prompt"
							className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
							placeholder="Enter a prompt..."
              onChange={(e) => setPrompt(e.target.value)}
						></textarea>
            {/* blue loading bar that uses the progress variable out of 100 to determine the length animated transitions smooth*/}

            <div className="w-full h-2 bg-blue-500 rounded-full" style={{ width: `${progress}%` }}></div>
            

            {/* generate a submit button that goes to the right of the prompt textbox, is light blue colored with 5px rounded corners and says "submit" */}
            <button
              className={`${prompt ? "bg-sky-500 hover:bg-sky-700" : "bg-stone-600 hover:bg-stone-600"} text-white font-bold py-2 px-4 rounded text-sm`}
              disabled={!prompt}
              onClick={onSubmit}
            >
              Submit
            </button>
            {/* spacer */}
            <div className="h-4"></div>

            {/* a temporary square that will eventually hold an image */}
            <div className="">
              {img ? <img src={img} /> : <></>}
            </div>
					</div>
				</div>
			</header>
		</div>
	)
}

export default App
