const SERVER_URL = "localhost:3001"

export interface GenerateResponse {
	job_id: string
}

export interface JobResponse {
    status: "queued" | "running" | "complete",
    progress?: number,
    file_url?: string
}

export const sendRequest = async (prompt: string): Promise<GenerateResponse> => {
    // return {job_id: "123"}
	const url = `${SERVER_URL}/generate/?prompt=${prompt}`
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	})
	if (response.ok) {
		const data = await response.json()
		return data
	} else throw new Error("Something went wrong")
}

export const checkJob = async (job_id: string): Promise<JobResponse> => {
	const url = `${SERVER_URL}/job/${job_id}`
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
	if (response.ok) {
		const data = await response.json()
		return data
	} else throw new Error("Something went wrong")
}
